<template>
  <BasePageContent>
    <BasePageHeader
      :title="Item.uuid ? Item.name : title"
      :description="
        Item.uuid
          ? Item.track_inventory
            ? '<span class=\'mr-2 badge size12\'>Trackable item</span>' +
              (Item.returnable
                ? '<span class=\'mr-2 badge size12 w3-theme-l2\'>Returnable item</span>'
                : '<span class=\'mr-2 badge size12 w3-light-grey\'>Non-returnable item</span>')
            : ''
          : ''
      "
    >
      <ul class="nk-block-tools g-1">
        <li v-if="Item.uuid && Item.track_inventory" class="nk-block-tools-opt">
          <a
            class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            title="Adjust Stock"
            href="#"
          >
            <em class="icon ni ni-opt"></em>
            <span>Adjust Stock</span>
          </a>
        </li>
        <li v-if="Item.uuid">
          <div class="drodown">
            <a
              href="#"
              class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
              data-toggle="dropdown"
            >
              <em class="icon ni ni-more-h"></em
            ></a>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="link-list-opt no-bdr">
                <li>
                  <a title="Duplicate" href="#">
                    <em class="dd-indc icon ni ni-copy"></em>
                    <span>Duplicate Item</span>
                  </a>
                </li>
                <li>
                  <a title="Disable" href="#">
                    <em class="dd-indc icon ni ni-copy"></em>
                    <span>Disable Item</span>
                  </a>
                </li>
                <li>
                  <a
                    title="Delete"
                    class="w3-text-red w3-hover-text-black"
                    href="#"
                  >
                    <em class="dd-indc icon ni ni-trash"></em>
                    <span> Delete Item</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nk-block-tools-opt">
          <a
            class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            title="Save Changes"
            href="#"
            @click.prevent="DoSave"
          >
            <em class="icon ni ni-save"></em>
            <span>Save</span>
          </a>
        </li>
        <li class="nk-block-tools-opt">
          <a href="#" @click.prevent="DoCancel"
            ><em class="icon ni ni-cross size36"></em
          ></a>
        </li>
      </ul>
    </BasePageHeader>
    <div class="p-2 w3-white">
      <template v-if="!uuid || ((uuid || item) && !Util.isEmpty(Item))">
        <div v-if="fetching" class="px-3">Loading...</div>
        <form v-else ref="updateItem" :id="ID" @submit.prevent>
          <p class="size13 w3-border-bottom p-3 mb-3">
            Items and services that you buy from vendors are used as items on
            Bills to record purchases, and the ones that you sell to customers
            are used as items on Invoices to record sales.
          </p>
          <div class="row w3-border-bottom px-3 pt-4 w3-animate-opacity">
            <div class="col-sm-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="type">Type</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div class="g-4 align-center flex-wrap">
                    <div class="g">
                      <div
                        class="custom-control custom-control-md custom-radio"
                      >
                        <input
                          type="radio"
                          class="custom-control-input"
                          name="type"
                          id="type_item"
                          v-model="Item.type"
                          value="item"
                        />
                        <label class="custom-control-label" for="type_item"
                          >Item</label
                        >
                      </div>
                    </div>
                    <div class="g">
                      <div
                        class="custom-control custom-control-md custom-radio"
                      >
                        <input
                          type="radio"
                          class="custom-control-input"
                          name="type"
                          id="type_service"
                          v-model="Item.type"
                          value="service"
                        />
                        <label class="custom-control-label" for="type_service"
                          >Service</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="hint form-text">
                    Select if this item is a physical good or a service. Item
                    type cannot be changes once used in a transaction
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="name">Name</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput id="name" v-model="Item.name" />
                </div>
              </div>
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="unit">Unit</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="unit"
                    v-model="Item.unit"
                    hint="Item will be quantified based on this unit. Eg kg, dozen, sqm"
                  />
                </div>
              </div>
              <div
                v-show="Item.type === 'item'"
                class="row g-2 w3-animate-opacity"
              >
                <div class="col-md-4"></div>
                <div class="col-md-8 mb-3">
                  <div class="custom-control custom-control-md custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="returnable"
                      v-model="Item.returnable"
                    />
                    <label class="custom-control-label" for="returnable"
                      >Item is returnable</label
                    >
                  </div>
                </div>
              </div>
              <div class="row g-2 w3-animate-opacity">
                <div class="col-md-4">
                  <label for="description">Item Description</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="description"
                    type="textarea"
                    v-model="Item.description"
                    hint="Item description"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-6 pb-2">
              <label class="w3-block" for="item_photo">
                <input
                  type="file"
                  multiple
                  class="custom-file-input"
                  id="item_photo"
                  name="item_photo[]"
                  @change="onFileChanged($event)"
                  accept="image/*"
                  v-show="false"
                  ref="itemPhoto"
                />
                <div
                  v-if="!ItemPhotos.length && !Item.item_photos_url?.length"
                  class="
                    upload-zone
                    dropzone
                    dz-clickable
                    w3-animate-opacity w3-hover-light-grey
                  "
                >
                  <div class="dz-message" data-dz-message>
                    <img
                      class="max-width150 mx-auto"
                      src="/images/upload.png"
                    />
                    <span class="dz-message-text mt-2"
                      >Select multiple item's photos for upload</span
                    >
                  </div>
                </div>
                <div
                  v-if="ItemPhotos.length || Item.item_photos_url?.length"
                  class="
                    upload-zone
                    dropzone
                    dz-clickable
                    py-0
                    w3-animate-opacity w3-hover-light-grey
                  "
                  style="min-height: 1px"
                >
                  <div class="dz-message" data-dz-message>
                    <span class="dz-message-text"
                      ><img class="width40 mr-2" src="/images/upload.png" />
                      Select multiple item's photos for upload</span
                    >
                  </div>
                </div>
              </label>
              <template v-if="ItemPhotos.length">
                <div class="row g-1 w3-animate-opacity mb-3">
                  <div
                    v-for="(photo, index) in ItemPhotos"
                    :key="index"
                    class="col-6 col-sm-4 col-md-3"
                  >
                    <div
                      class="w3-border w3-border-light-grey background-image"
                      style="aspect-ratio: 1 / 1"
                      :style="{ backgroundImage: 'url(' + photo.path + ')' }"
                    ></div>
                    <a
                      href="#"
                      @click.prevent="RemovePhoto(photo)"
                      class="
                        py-1
                        size12
                        w3-hover-red w3-center w3-light-grey w3-block
                      "
                      ><em class="icon ni ni-trash-empty-fill"></em> Remove</a
                    >
                  </div>
                </div>
              </template>
              <a
                v-if="Item.item_photo && resetPhotos"
                href="#"
                @click.prevent="RestorePhotos"
                class="w3-block w3-center w3-hover-light-grey p-1 my-2 size13"
                >Restore initial photos</a
              >
              <template v-if="Item.item_photos_url?.length">
                <div class="row g-1 w3-animate-opacity mb-3">
                  <div
                    v-for="(photo, index) in Item.item_photos_url"
                    :key="index"
                    class="col-6 col-sm-4 col-md-3"
                  >
                    <div
                      class="w3-border w3-border-light-grey background-image"
                      style="aspect-ratio: 1 / 1"
                      :style="{ backgroundImage: 'url(' + photo.url + ')' }"
                    ></div>
                    <a
                      href="#"
                      @click.prevent="RemoveExistingPhoto(photo.uuid)"
                      class="
                        py-1
                        size12
                        w3-hover-red w3-center w3-light-grey w3-block
                      "
                      ><em class="icon ni ni-trash-empty-fill"></em> Remove</a
                    >
                  </div>
                </div>
              </template>

              <p class="hint w3-center mt-3">
                The first image will be used as the item's feature image
              </p>
            </div>
          </div>
          <div
            v-show="Item.type === 'item'"
            class="row w3-border-bottom px-3 pt-4 w3-animate-opacity"
          >
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="sku">SKU</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="sku"
                    v-model="Item.sku"
                    hint="Stock Keeping Unit"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="upc">UPC</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="upc"
                    v-model="Item.upc"
                    hint="Unique Twelve digit number of the bar code (Universal Item
              Code)"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="mpn">MPN</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="mpn"
                    v-model="Item.mpn"
                    hint="Manufacturing Part Number (MPN) identifies a part design"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="ean">EAN</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="ean"
                    v-model="Item.ean"
                    hint="International Article Number (also known as European Article Number)"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="manufacturer">Manufacturer</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="manufacturer"
                    v-model="Item.manufacturer"
                    hint=""
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="brand">Brand</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput id="brand" v-model="Item.brand" hint="" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="model">Model</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput id="model" v-model="Item.model" hint="" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="Item.type === 'item'"
            class="row w3-border-bottom px-3 pt-4 w3-animate-opacity"
          >
            <div class="col-md-7">
              <div class="row g-2">
                <div class="col-md-4">
                  <label class="mb-0" for="length">Dimension</label>
                  <div class="hint form-text">(Length X Width X Height)</div>
                </div>
                <div class="col-md-8 mb-2">
                  <div class="w3-cell-row w3-white g-0 w3-border">
                    <div class="w3-cell">
                      <BaseInput
                        class="w3-center border-0 bordered-right"
                        id="length"
                        v-model="Item.length"
                        placeholder="L"
                      />
                    </div>
                    <div
                      class="w3-cell align-center justify-center"
                      style="width: 5px !important"
                    >
                      x
                    </div>
                    <div class="w3-cell">
                      <BaseInput
                        class="w3-center border-0 bordered-right"
                        id="width"
                        v-model="Item.width"
                        placeholder="W"
                      />
                    </div>
                    <div
                      class="w3-cell align-center justify-center"
                      style="width: 5px !important"
                    >
                      x
                    </div>
                    <div class="w3-cell">
                      <BaseInput
                        class="w3-center border-0 bordered-right"
                        id="height"
                        v-model="Item.height"
                        placeholder="H"
                      />
                    </div>
                    <div class="w3-cell" style="min-width: 70px !important">
                      <div class="form-control-select bg-lighter">
                        <select
                          class="form-control form-control-lg w3-light-grey"
                          id="dimension-unit"
                          v-model="Item.dimension_unit"
                        >
                          <option value=""></option>
                          <option value="cm">cm</option>
                          <option value="mm">mm</option>
                          <option value="m">m</option>
                          <option value="in">in</option>
                          <option value="ft">ft</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div
                v-show="Item.weight_measure === 'density'"
                class="row g-2 w3-animate-opacity"
              >
                <div class="col-md-4">
                  <label class="mb-0" for="density">Density</label>
                  <a
                    href="#"
                    class="w3-block my-0 size12 w3-text-theme"
                    @click.prevent="Item.weight_measure = 'weight'"
                    >Use weight</a
                  >
                </div>
                <div class="col-md-8 mb-2">
                  <div class="input-group w3-border">
                    <input
                      id="density"
                      type="text"
                      class="form-control form-control-lg border-0"
                      v-model="Item.density"
                      placeholder="Density"
                    />
                    <div class="input-group-append">
                      <select
                        class="form-control form-control-lg w3-light-grey"
                        id="density-unit"
                        v-model="Item.density_unit"
                        style="width: 120px"
                      >
                        <option value=""></option>
                        <option value="g/sqcm">g/sqcm</option>
                        <option value="kg/sqm">kg/sqm</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="Item.weight_measure === 'weight'"
                class="row g-2 w3-animate-opacity"
              >
                <div class="col-md-4">
                  <label class="mb-0" for="weight">Weight</label>
                  <a
                    href="#"
                    class="w3-block my-0 size12 w3-text-theme"
                    @click.prevent="Item.weight_measure = 'density'"
                    >Use density</a
                  >
                </div>
                <div class="col-md-8 mb-2">
                  <div class="row g-1">
                    <div class="col-md-6 mb-2">
                      <BaseInput
                        id="weight"
                        v-model="Item.weight"
                        placeholder="Weight"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <div class="form-control-wrap">
                        <div class="form-control-select">
                          <select
                            class="form-control form-control-lg"
                            id="weight_unit"
                            v-model="Item.weight_unit"
                          >
                            <option value=""></option>
                            <option value="g">gram</option>
                            <option value="kg">Kilogram</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row w3-border-bottom px-3 pt-4 w3-animate-opacity">
            <div class="col-md-6">
              <div
                class="custom-control custom-control-lg custom-checkbox mb-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is_sale"
                  v-model="Item.is_sale"
                />
                <label class="custom-control-label" for="is_sale"
                  >Sale Information
                  <p class="hint">
                    Enable this option if you sell this item
                  </p></label
                >
              </div>
              <div v-show="Item.is_sale" class="row g-2 w3-animate-opacity">
                <div class="col-md-4">
                  <label for="sale_price">Selling price</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div class="input-group input-group-lg">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        v-html="Util.CurrencySymbol"
                      ></span>
                    </div>
                    <input
                      type="text"
                      id="sale_price"
                      v-model="Item.sale_price"
                      @format-currency="Util.CurrencyFormat"
                      class="form-control"
                    />
                  </div>
                  <p class="hint">
                    Default rate at which to sell this item. You can adjust this
                    when creating Sales orders and Invoices
                  </p>
                </div>
              </div>

              <div v-show="Item.is_sale" class="row g-2 w3-animate-opacity">
                <div class="col-md-4">
                  <label for="sale_tax">Sales Tax</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div
                    class="g-2 justify-start align-start flex-wrap flex-column"
                  >
                    <div
                      v-for="(option, i) in SalesTax"
                      :key="option.value"
                      class="g"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="'sale_tax-' + i"
                          v-model="Item.sale_tax"
                          :value="option.value"
                        />
                        <label
                          class="custom-control-label"
                          :for="'sale_tax-' + i"
                          >{{ option.text }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="hint my-2">
                    Select default sales tax(es) to be applied to this item. You
                    can adjust this when creating Sales orders and Invoices
                  </div>
                  <a
                    href="#"
                    @click.prevent="addTax = true"
                    title="Add a new tax"
                    class="btn btn-white btn-dim btn-outline-light my-1"
                  >
                    <em class="dd-indc icon ni ni-plus"></em>
                    <span class="d-none d-md-inline">Add new tax</span>
                  </a>
                  <AddTax
                    :toggle-by="addTax"
                    @oncancel="addTax = false"
                    @onsave="addTax = false"
                    title="Add new tax"
                  ></AddTax>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="custom-control custom-control-lg custom-checkbox mb-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is_purchase"
                  v-model="Item.is_purchase"
                />
                <label class="custom-control-label" for="is_purchase"
                  >Purchase Information
                  <p class="hint">
                    Enable this option if you purchase this item
                  </p></label
                >
              </div>
              <div v-show="Item.is_purchase" class="row g-2 w3-animate-opacity">
                <div class="col-md-4">
                  <label for="cost_price">Cost price</label>
                </div>
                <div class="col-md-8 mb-3">
                  <div class="input-group input-group-lg">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        v-html="Util.CurrencySymbol"
                      ></span>
                    </div>
                    <input
                      type="text"
                      id="cost_price"
                      v-model="Item.cost_price"
                      class="form-control"
                    />
                  </div>
                  <p class="hint">
                    Default rate at which your purchase this item. You can
                    adjust this when creating Sales orders and Invoices
                  </p>
                </div>
              </div>
            </div>
          </div>
          <template v-if="Item.is_purchase">
            <div
              class="row px-3 pt-4 w3-animate-opacity"
              :class="!Item.track_inventory ? 'w3-border-bottom' : ''"
            >
              <div class="col">
                <div
                  class="custom-control custom-control-lg custom-checkbox mb-3"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="track_inventory"
                    v-model="Item.track_inventory"
                  />
                  <label class="custom-control-label" for="track_inventory"
                    >Enable inventory tracking for this item
                    <p class="hint">
                      Inventory tracking option cannot be changed once a
                      transaction have been created for this item
                    </p></label
                  >
                </div>
              </div>
            </div>
            <div
              v-if="Item.track_inventory"
              class="row w3-border-bottom px-3 pt-4 w3-animate-opacity"
            >
              <div class="col-md-6">
                <div class="row g-2 w3-animate-opacity">
                  <div class="col-md-4">
                    <label for="opening_stock">Opening Stock</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    <input
                      type="text"
                      id="opening_stock"
                      v-model="Item.opening_stock"
                      class="form-control"
                    />
                    <p class="hint">The qualtity available for sale at start</p>
                  </div>
                </div>
                <div class="row g-2 w3-animate-opacity">
                  <div class="col-md-4">
                    <label for="reorder_point">Low Stock Point</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    <input
                      type="text"
                      id="reorder_point"
                      v-model="Item.reorder_point"
                      class="form-control"
                    />
                    <p class="hint">
                      The minimum quantity of stock at which you will be
                      notified for Reorder
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="Item.is_purchase" class="col-md-6">
                <div class="row g-2 w3-animate-opacity">
                  <div class="col-md-4">
                    <label for="opening_stock_price"
                      >Opening Stock Cost Price</label
                    >
                  </div>
                  <div class="col-md-8 mb-3">
                    <div class="input-group input-group-lg">
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          v-html="Util.CurrencySymbol"
                        ></span>
                      </div>
                      <input
                        type="text"
                        id="opening_stock_price"
                        v-model="Item.opening_stock_price"
                        class="form-control"
                      />
                    </div>
                    <p class="hint">Rate at which this item was purchased</p>
                  </div>
                </div>
                <div class="row g-2 w3-animate-opacity">
                  <div class="col-md-4">
                    <label for="vendor">Preferred Item Vendor</label>
                  </div>
                  <div class="col-md-8 mb-3">
                    <div class="form-control-select">
                      <select
                        class="form-control form-control-lg"
                        id="vendor"
                        v-model="Item.vendor"
                      >
                        <option value=""></option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row px-3 pt-4 w3-animate-opacity">
            <div class="col-md-10">
              <div class="row g-2 w3-animate-opacity">
                <div class="col-md-3">
                  <label for="internal_note">Internal note</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="internal_note"
                    type="textarea"
                    v-model="Item.internal_note"
                    hint=""
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </form>
      </template>
      <div
        v-else-if="!fetching && (uuid || item) && Util.isEmpty(Item)"
        class="p-5 w3-center"
      >
        <img src="/images/item-not-found.png" class="mb-4" />
        <h4>Item not found</h4>
        <router-link
          :to="{ name: 'items' }"
          class="w3-button w3-white w3-border"
        >
          <span class="">Manage Inventory</span>
        </router-link>
      </div>
    </div>
  </BasePageContent>
</template>

<script>
import { computed, ref, toRef } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
import AddTax from "@/components/ui/settings/taxes/AddTax.vue";
export default {
  components: {
    AddTax,
  },
  props: {
    id: {
      type: String,
    },
    uuid: {
      type: String,
    },
    item: {
      type: Object,
    },
    title: {
      type: String,
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    itemDef: {
      type: Object,
    },
  },
  emits: ["oncancel", "onsave"],
  setup(props, { emit }) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const UUID = toRef(props, "uuid");
    const ITEM = toRef(props, "item");
    const Settings = computed(() => store.getters.settings);
    const addTax = ref(false);
    const fields = {
      uuid: "",
      merchant_uuid: "",
      name: "",
      type: props.itemDef?.itemType ?? "item",
      unit: "",
      sku: "",
      returnable: true,
      description: "",
      length: "",
      width: "",
      height: "",
      dimension_unit: "m",
      weight_measure: "density",
      density: "",
      density_unit: "kg/sqm",
      weight: "",
      weight_unit: "kg",
      manufacturer: "",
      brand: "",
      model: "",
      mpn: "",
      upc: "",
      ean: "",
      is_sale: true,
      sale_price: "",
      sale_account: "",
      sale_description: "",
      sale_tax: [],
      is_purchase: false,
      cost_price: "",
      expense_account: "",
      purchase_description: "",
      purchase_tax: [],
      track_inventory: false,
      inventory_account: "",
      opening_stock: "",
      opening_stock_price: "",
      reorder_point: "",
      vendor: "",
      item_photo: [],
      internal_note: "",
    };
    const Taxes = computed(() => store.getters["taxes/taxes"]);
    const TaxesArray = ref([]);
    const SalesTax = computed(() => {
      const taxes = [];
      Taxes.value.forEach((tax) => {
        TaxesArray.value.push(tax.uuid);
        taxes.push({
          value: tax.uuid,
          text: tax.tax_abbr,
        });
      });
      return taxes;
    });
    const Item = ref({});
    const updateItem = ref(null);
    const itemPhoto = ref(null);
    const ItemPhotos = ref([]);
    const Paths = ref([]);
    const fetching = ref(true);
    let original_item_photo = null;
    let original_item_photos_url = null;
    const GetItem = async () => {
      Item.value = {};
      if (props.uuid) {
        await store.dispatch("items/getItem", props.uuid);
        const _item = store.state.items.item;
        Item.value = {
          ..._item,
        };
        original_item_photo = _item.item_photo;
        original_item_photos_url = _item.item_photos_url;
      } else if (ITEM.value) {
        Item.value = ITEM.value;
      } else {
        Item.value = fields;
      }
      fetching.value = false;
    };
    GetItem();
    const InventoryTrackable = ref(true);
    const toggleInventryTracking = () => {
      InventoryTrackable.value = !InventoryTrackable.value;
    };
    toggleInventryTracking();

    function onFileChanged($event) {
      const target = $event.target;
      if (target && target.files) {
        for (let i = 0; i < target.files.length; i++) {
          target.files[i].path = URL.createObjectURL(target.files[i]);
          if (!Paths.value.includes(target.files[i].path)) {
            Paths.value.push(target.files[i].path);
            ItemPhotos.value.push(target.files[i]);
          }
        }
      }
    }
    const DoSave = async () => {
      const result = await store.dispatch("items/updateItem", {
        Item: FormItem.value,
        ItemPhotos: ItemPhotos.value,
      });
      if (result?.status === "success") emit("onsave", FormItem.value);
    };
    const DoCancel = () => {
      Item.value = { ...fields };
      emit("oncancel");
    };
    const Validate = () => {
      return true;
    };

    const RemovePhoto = (photo) => {
      Util.removeArrayItem(ItemPhotos.value, photo);
    };
    const resetPhotos = computed(() => {
      return (
        Item.value.item_photos?.length !== Item.value.item_photos_url?.length
      );
    });
    const RestorePhotos = () => {
      Item.value.item_photo = original_item_photo;
      Item.value.item_photos_url = original_item_photos_url;
    };
    const RemoveExistingPhoto = (uuid) => {
      Item.value.item_photo = Item.value.item_photo.filter(
        (p) => p.uuid !== uuid
      );
      Item.value.item_photos_url = Item.value.item_photos_url.filter(
        (p) => p.uuid !== uuid
      );
    };

    const FormItem = computed(() => {
      const _item = { ...Item.value };
      if (!Util.isEmpty(_item)) {
        const _tax_array = TaxesArray.value;
        if (Item.value.sale_tax && Item.value.sale_tax.length) {
          _item.sale_tax = Item.value.sale_tax.filter((tax) =>
            _tax_array.includes(tax)
          );
        }
        return _item;
      }
      return {};
    });

    return {
      fetching,
      addTax,
      SalesTax,
      onFileChanged,
      store,
      Settings,
      ID,
      UUID,
      Item,
      updateItem,
      DoSave,
      DoCancel,
      InventoryTrackable,
      toggleInventryTracking,
      Validate,
      Util,
      itemPhoto,
      ItemPhotos,
      RemovePhoto,
      RemoveExistingPhoto,
      resetPhotos,
      RestorePhotos,
    };
  },
};
</script>