<template>
  <BasePageContent>
    <BasePageHeader
      :title="Tax.uuid ? Tax.tax_name + ' (' + Tax.tax_abbr + ')' : title"
      description=""
    >
      <ul class="nk-block-tools g-1">
        <li v-if="Tax.uuid">
          <div class="drodown">
            <a
              href="#"
              class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
              data-toggle="dropdown"
            >
              <em class="icon ni ni-more-h"></em
            ></a>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="link-list-opt no-bdr">
                <li>
                  <a
                    title="Duplicate"
                    href="#"
                    @click.prevent="Duplicate(Tax.uuid)"
                  >
                    <em class="dd-indc icon ni ni-copy"></em>
                    <span>Duplicate Tax</span>
                  </a>
                </li>
                <li>
                  <a title="Disable" href="#">
                    <em class="dd-indc icon ni ni-copy"></em>
                    <span>Disable Tax</span>
                  </a>
                </li>
                <li>
                  <a
                    title="Delete"
                    class="w3-text-red w3-hover-text-black"
                    href="#"
                  >
                    <em class="dd-indc icon ni ni-trash"></em>
                    <span> Delete Tax</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nk-block-tools-opt">
          <a
            class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            title="Save Changes"
            href="#"
            @click.prevent="DoSave"
          >
            <em class="icon ni ni-save"></em>
            <span>Save</span>
          </a>
        </li>
        <li class="nk-block-tools-opt">
          <a href="#" @click.prevent="DoCancel"
            ><em class="icon ni ni-cross size36"></em
          ></a>
        </li>
      </ul>
    </BasePageHeader>
    <div class="p-2 w3-white">
      <div v-if="fetching">Loading...</div>
      <form v-else ref="updateTax" :id="ID" @submit.prevent>
        <div class="row g-2">
          <div class="col-md-4">
            <label for="tax_name">Tax Name</label>
          </div>
          <div class="col-md-8 mb-3">
            <BaseInput id="tax_name" v-model="Tax.tax_name" />
          </div>
        </div>
        <div class="row g-2">
          <div class="col-md-4">
            <label for="tax_abbr">Tax Abbreviation</label>
          </div>
          <div class="col-md-8 mb-3">
            <BaseInput
              id="tax_abbr"
              class="width150"
              v-model="Tax.tax_abbr"
              hint="The short form of the tax"
            />
          </div>
        </div>
        <div class="row g-2">
          <div class="col-md-4">
            <label for="tax_rate">Tax Rate</label>
          </div>
          <div class="col-md-8 mb-3">
            <div class="input-group input-group-lg width200">
              <input
                type="text"
                id="tax_rate"
                v-model="Tax.tax_rate"
                @blur="Util.formatOn.Blur"
                @focus="Util.formatOn.Focus"
                class="form-control"
              />
              <!-- @format-number="Util.PercentageFormat" -->
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-md-4">
            <label for="tax_id">Tax ID</label>
          </div>
          <div class="col-md-8 mb-3">
            <BaseInput
              id="tax_id"
              v-model="Tax.tax_id"
              hint="Government issued tax number"
            />
          </div>
        </div>
        <div class="row g-2 w3-animate-opacity">
          <div class="col-md-4"></div>
          <div class="col-md-8 mb-3">
            <div class="custom-control custom-control-md custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="show_tax_id"
                v-model="Tax.show_tax_id"
              />
              <label class="custom-control-label" for="show_tax_id"
                >Show Tax ID in invoices</label
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </BasePageContent>
</template>

<script>
import { computed, ref, toRef } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
export default {
  components: {},
  props: {
    id: {
      type: String,
    },
    uuid: {
      type: String,
    },
    tax: { type: Object },
    title: {
      type: String,
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  emits: ["oncancel", "onsave"],
  setup(props, { emit }) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const UUID = toRef(props, "uuid");
    const TAX = toRef(props, "tax");
    const Settings = computed(() => store.getters.settings);
    const fields = {
      uuid: "",
      merchant_uuid: "",
      busness_uuid: "",
      tax_name: "",
      tax_abbr: "",
      tax_rate: 0,
      tax_id: "",
      show_tax_id: true,
      tax_desc: "",
    };
    const Tax = ref({});
    const fetching = ref(true);
    const GetTax = async () => {
      Tax.value = {};
      if (props.uuid) {
        await store.dispatch("taxes/getTax", props.uuid);
        Tax.value = store.state.taxes.tax;
      } else if (TAX.value) {
        Tax.value = TAX.value;
      } else {
        Tax.value = fields;
      }
      fetching.value = false;
    };

    GetTax();

    const DoSave = async () => {
      const result = await store.dispatch("taxes/updateTax", Tax.value);
      if (result?.status === "success") emit("onsave", Tax.value);
    };
    const DoCancel = () => {
      Tax.value = { ...fields };
      emit("oncancel");
    };
    const Validate = (view) => {
      console.log(view);
      return true;
    };
    const Duplicate = async (uuid) => {
      const _tax = await store.dispatch("taxes/duplicateTax", uuid);
      if (!Util.isEmpty(_tax)) {
        delete _tax.created_at;
        delete _tax.created_by;
        delete _tax.id;
        delete _tax.updated_at;
        delete _tax.updated_by;
        _tax.uuid = "";
        Tax.value = _tax;
      }
    };
    return {
      fetching,
      store,
      Settings,
      Duplicate,
      ID,
      UUID,
      Tax,
      DoSave,
      DoCancel,
      Validate,
      Util,
    };
  },
};
</script>